p + ul, p + ol {
    margin-top: -1.25em;
}

address {
    font-style: normal;
}

.hide-if-small {
    display: none;

    @media only screen and (min-width: 46em) {
        display: initial;
    }
}

.light-background {
    background: #fafafa;
}

.dark-background {
    background: #f1f1f1;
}

.img-full-width {
    width: 100%;
    height: auto;
}

.img-full-height {
    height: 100%;
    width: auto;
}

img.img-fit {
    width: 100%;
    object-position: 50% 50%;
	object-fit: cover;
	overflow: hidden;
}

// Fix for lack of support for object-fit in IE and Edge
.img-fit-container {

    &.compat-object-fit {
        background-size: cover;
        background-position: center center;

        img.img-fit {
            // hide image if object-fit is not supported - opacity to 0
            opacity: 0;
        }
    }
}

.image-with-text-container {
    width: 100%;
    overflow: hidden;
}

.image-text {
   position: absolute;
   bottom: 0.0em;
   margin-bottom: 0;

   p {
       color: white;
       background: rgb(0, 0, 0); // fallback color
       background: rgba(0, 0, 0, 0.5);
       padding: 0.5em;
       margin-right: 3em;
   }
}

/* Logos des partenaires */

img.part-logo {
/*    max-width: 1/1 * 100%;*/
    max-height: 10em;
    padding: 0 0.2em 0.2em 0;

    @media only screen and (min-width: 46em) {
        padding: 0.2em;
    }

    @media only screen and (min-width: 61em) {
        padding: 0.2em;
    }
}

img.part-logo-narrow {
/*    max-width: 1/2 * 100%;*/
    max-height: 8em;

    @media only screen and (min-width: 46em) {
/*        max-width: 1/3 * 100%;*/
        max-height: 10em;
    }

    @media only screen and (min-width: 61em) {
/*        max-width: 1/4 * 100%;*/
        max-height: 10em;
    }
}

img.part-logo-wide {
/*    max-width: 1 * 100%;*/
    max-height: 8em;

    @media only screen and (min-width: 46em) {
/*        max-width: 1/2 * 100%;*/
        max-height: 10em;
    }

    @media only screen and (min-width: 61em) {
/*        max-width: 1/2 * 100%;*/
        max-height: 10em;
    }
}

img.part-logo-xwide {
/*    max-width: 1 * 100%;*/
    max-height: 8em;

    @media only screen and (min-width: 46em) {
/*        max-width: 1 * 100%;*/
        max-height: 10em;
    }

    @media only screen and (min-width: 61em) {
/*        max-width: 1/2 * 100%;*/
        max-height: 10em;
    }
}

/* Bannière */
.banner-img {
    margin-top: -0.5em;

    @media only screen and (min-width: 46em) {
        margin-top: -1.0em;
    }

    @media only screen and (min-width: 61em) {
        margin-top: -1.5em;
    }
}


/* Alerte */
.alert {
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        font-family: 'zilla_slab_highlight', sans-serif;
        font-weight: 700;
        margin: 0 0.2rem;
    }
}

.alert-content {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: 46em) {
        max-width: 80%;
    }

    @media only screen and (min-width: 61em) {
        max-width: 65%;
    }
}

.alert-icon {
    width: 80px;
    padding: 0 10px 0 0;

    @media only screen and (min-width: 46em) {
        width: 100px;
        padding: 0 15px 0 0;
    }

    @media only screen and (min-width: 61em) {
        width: 200px;
        padding: 0 20px 0 0;
    }
}


/* Contacts */
.contacts, .contacts a {
    color: #116AD4;
}

/* Facebook */
#facebook {
    position: absolute;
    text-align: center;
    height: 100%;
    width: 100%;
    background-image: url("../img/facebook/facebook_photo_800.jpg");
    background-color: #888;
    background-blend-mode: screen;
    background-position: middle;
    max-width: 100%;
}

#facebook, #facebook a {
    font-family: panefresco;
    font-weight: 900;
    color: #fff;
}

#facebook-text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 70%;
    height: 30%;
    margin: auto;
    padding-top: 0.25em;
}

/* Liste d'activités */
.activities {
    background: #f1f1f1;
}

.activities-list {
    display: table;
    list-style: none;
    margin: 1em 0 0 0;
    padding-left: 1em;
}

.activity-item {
    padding-left: 5em;
    position: relative;
    min-height: 6rem;

    @media only screen and (min-width: 46em) {
        min-height: 9rem;
    }

    @media only screen and (min-width: 61em) {
        min-height: 6rem;
    }

    h3 {
        font-family: panefresco;
        font-weight: 600;
    }

    p {
        font-family: 'zilla_slab', sans-serif;
        font-weight: 100;
    }

    &:before {
        content: '';
        width: 3.125em;
        height: 3.125em;
        display: inline-block;
        position: absolute;
        top: 0.3125em;
        left: 0.625em;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}


.activity-style:before {
    background-image: url("../img/icons/two-ping-pong-rackets.svg");
}

.activity-baby:before {
    background-image: url("../img/icons/crawling-baby-silhouette.svg");
}

.activity-senior:before {
    background-image: url("../img/icons/cardiogram-and-heart.svg");
}

.activity-evolution:before {
    background-image: url("../img/icons/line-chart.svg");
}

.activity-formation:before {
    background-image: url("../img/icons/classroom.svg");
}

.activity-stage:before {
    background-image: url("../img/icons/backpacker-running.svg");
}

/* text-block */
.text-block {
    h3 {
        font-family: panefresco;
        font-weight: 600;
    }

    p, ul li, ol li, address {
        font-family: 'zilla_slab', sans-serif;
        font-weight: 100;
    }

    ul, p ol {
        padding-left: 1.25em;
    }

    address {
        margin-left: 1.875em;
    }
}


/* Salles */
.maps {
    background: #fafafa;
    padding-right: 0.625em;
}

.map-header {
    height: 5rem;
}

.map {
    padding-right: 0.625em;
    padding-left: 0.625em;
    margin-bottom: 0.625em;
}

.small-map {
    height: 18rem;
}

.big-map {
    height: 25rem;
}

.flex-map {
    height: 100%;
    min-height: 18em;
}

.salle-header {
    h2 {
        font-family: panefresco;
        font-weight: 600;
    }

    .salle-header p {
        font-family: 'zilla_slab', sans-serif;
        font-weight: 100;
    }
}

#salle-compiegne div.col-12 {
    height: 18rem;
}


/* Equipes */
.equipes-list {
    list-style: none;
}

.equipe {
    font-family: panefresco;
    font-weight: 600;
    margin-top: 0.4em;
    padding: 0.5em;

    a {
        text-decoration: none;
        color: #fff;
    }
}

.chp-r2 {
    background: #9B91BF;
}

.chp-r3 {
    background: #8880A8;
}

.chp-r4 {
    background: #777094;
}

.chp-d2 {
    background: #028DD9;
}

.chp-d3 {
    background: #0281C7;
}

.chp-d4 {
    background: #026FAB;
}


/* Sponsors - partenaires */
.sponsors {
    background: #f9f9f9;
}

.sponsors-image-container {
    background: #fff;
}

.sponsors-image {
    max-height: 14em;
    max-width: 100%;
}


/* Error page */
.error {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8em;
    font-size: 1.8em;
    line-height: 1.1em;

    .logo {
        float: none;
        width: 20rem;
        height: 20rem;
    }

    h1 {
        font-size: 2em;
        margin-bottom: 0.5em;
    }
}

/* Footer - pied de page */
.footer {
    background: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
        float: none;
        display: inline-block;
        width: 10rem;
        height: 10rem;
    }
}

@media only screen and (min-width: 46em) {
    .logo {
        float: left;
    }
}

.logo {
    display: block;
    background-image: url("../img/logos/logo_bcl_600.png");
    background-size:     contain;
    background-repeat:   no-repeat;
    background-position: center center;
/*    transition: all .2s;
    -webkit-animation-delay: .2s;
    animation-delay: .2s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
*/

    @media only screen and (min-width: 46em) {
        float: left;
    }
}


/* Copyright */
.copyright {
    background: #ddd;
    font-family: panefresco;
    font-weight: 500;
    color: #888;
    padding-top: 0.325em;
    text-align: right;
}
